import { useEffect, useState } from "react";
import {
   MdArrowLeft,
   MdArrowRight,
   MdClose,
   MdOutlineKeyboardArrowLeft,
   MdOutlineKeyboardArrowRight,
} from "react-icons/md";

const images = [
   {
      src: "/assets/imgs/gallery/1.jpeg",
      width: 100,
      height: 100,
   },
   {
      src: "/assets/imgs/gallery/2.jpeg",
      width: 100,
      height: 100,
   },
   {
      src: "/assets/imgs/gallery/3.jpeg",
      width: 100,
      height: 100,
   },
   {
      src: "/assets/imgs/gallery/4.jpeg",
      width: 100,
      height: 100,
   },
   {
      src: "/assets/imgs/gallery/5.jpeg",
      width: 100,
      height: 100,
   },
   {
      src: "/assets/imgs/gallery/6.jpeg",
      width: 100,
      height: 100,
   },
   {
      src: "/assets/imgs/gallery/7.jpeg",
      width: 100,
      height: 100,
   },
   {
      src: "/assets/imgs/gallery/8.jpeg",
      width: 100,
      height: 100,
   },
   {
      src: "/assets/imgs/gallery/9.jpeg",
      width: 100,
      height: 100,
   },
   {
      src: "/assets/imgs/gallery/10.jpeg",
      width: 100,
      height: 100,
   },
   {
      src: "/assets/imgs/gallery/11.jpeg",
      width: 100,
      height: 100,
   },
   {
      src: "/assets/imgs/gallery/12.jpeg",
      width: 100,
      height: 100,
   },
];

export default function Images() {
   const [selectedImageIndex, setSelectedImageIndex] = useState(null);

   useEffect(() => {
      document.title = "בובות ענק להשכרה - בובה במסיבת יום ההולדת שלכם";
   }, []);

   useEffect(() => {
      if (selectedImageIndex !== null) {
         document.body.classList.add("no-scroll");
      } else {
         document.body.classList.remove("no-scroll");
      }

      return () => {
         document.body.classList.remove("no-scroll");
      };
   }, [selectedImageIndex]);

   const handleImageClick = (index) => {
      setSelectedImageIndex(index);
   };

   const handleClose = () => {
      setSelectedImageIndex(null);
   };

   const handleRight = () => {
      if (selectedImageIndex == null) return;

      if (selectedImageIndex == images.length - 1) setSelectedImageIndex(0);
      else setSelectedImageIndex(selectedImageIndex + 1);
   };

   const handleLeft = () => {
      if (selectedImageIndex == null) return;

      if (selectedImageIndex == 0) setSelectedImageIndex(images.length - 1);
      else setSelectedImageIndex(selectedImageIndex - 1);
   };

   return (
      <main className="app-container lg:mt-[130px] mt-[120px] flex flex-col mb-5">
         <h1 className="text-4xl lg:text-6xl font-bold text-center mb-3">
            בובות ענק להשכרה
         </h1>
         <h3 className="text-3xl lg:text-4xl font-semibold text-center mb-3">
            בובות ענק ליום הולדת
         </h3>
         <p className="text-base lg:text-lg text-neutral-600 text-center mb-5">
            מגוון בובות ענק ליום הולדת לילדים שלכם להשכרה ולבובה בהפתעה אצלכם
            ביום הולדת שמגיעות עם שירים ושיעשו את היום הולדת שלכם מיוחדת במינה
         </p>
         <div className="grid grid-cols-2 gap-3 lg:grid-cols-3">
            {images.map((item, index) => (
               <div
                  key={index}
                  className="relative bg-white shadow-md flex justify-center items-center aspect-square cursor-pointer"
                  onClick={() => handleImageClick(index)}
               >
                  <img
                     alt="Image"
                     src={item.src}
                     className="w-full h-full object-cover"
                  />
               </div>
            ))}
         </div>

         <p className="mt-5 text-lg text-end">
            בובות הענק שלנו הן הכי יפות ויוקרתיות שיש בישראל ומחכות להגיע ליום
            הולדת שלכם עם מגוון של דמויות נערצות וחוויה לא נשכחת שכל הילדים
            יעופו עליה!!!
         </p>

         <p className="mt-5 text-lg text-end">
            השכרת בובות ענק לאירועים יכולה להתאים לכל סוג של אירוע ילדים. לא
            משנה למה ואיך, וגם לא משנה כמה – כאשר משלבים בובות זה תמיד עובד
            ותמיד מצליח לשדרג את האירוע שלכם. מבין כל האטרקציות לאירועים, השכרת
            בובות ענק היא אחת מהאפשרויות המהנות ביותר, ובעלת השפעה רבה על הצלחת
            האירוע.
         </p>

         {selectedImageIndex !== null && (
            <div className="fixed top-0 bottom-0 right-0 left-0 z-50 flex justify-center items-center lg:p-10 p-5 image-background">
               <div className="relative w-full h-full">
                  <img
                     alt="Image"
                     src={images[selectedImageIndex].src}
                     className="w-full h-full object-contain"
                  />
                  <a
                     href="#"
                     className="absolute top-0"
                     onClick={() => handleClose()}
                  >
                     <MdClose size={32} color="#FFF" />
                  </a>
                  <a
                     href="#"
                     className="absolute top-1/2 -translate-y-1/2 right-0"
                     onClick={() => handleRight()}
                  >
                     <MdOutlineKeyboardArrowRight size={48} color="#FFF" />
                  </a>
                  <a
                     href="#"
                     className="absolute top-1/2 -translate-y-1/2"
                     onClick={() => handleLeft()}
                  >
                     <MdOutlineKeyboardArrowLeft size={48} color="#FFF" />
                  </a>
               </div>
            </div>
         )}
      </main>
   );
}
