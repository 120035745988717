import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./pages/Home";
import Navbar from "./components/navbar";
import Footer from "./components/footer";
import Images from "./pages/Images";
import { MdPhone } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa";

const router = createBrowserRouter([
   {
      path: "/",
      element: <Home />,
   },
   {
      path: "/images",
      element: <Images />,
   },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
   <React.StrictMode>
      <Navbar />
      <div className="lg:hidden fixed bottom-5 left-5 overflow-hidden p-4 bg-cyan-500 rounded-full shadow-lg z-50">
         <a href="tel:+972545733333">
            <MdPhone size={28} color="#FFF" />
         </a>
      </div>
      <div className="lg:hidden fixed bottom-5 right-5 overflow-hidden p-4 bg-pink-400 rounded-full shadow-lg z-50">
         <a
            href="https://wa.me/972545733333?text=היי,%20אני%20מעוניין%20להזמין%20בובה"
            target="_blank"
         >
            <FaWhatsapp size={28} color="#FFF" />
         </a>
      </div>
      <RouterProvider router={router} />
      <Footer />
   </React.StrictMode>
);
