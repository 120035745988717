export default function Character({ src, name, description, isPopular }) {
   return (
      <div className="character-card">
         <div className="character-image flex items-center justify-center overflow-hidden rounded-full border border-gray-400 w-52 h-52">
            <div className="relative w-full h-full z-[1]">
               <img alt={name} width={200} height={200} src={src} />
               {/* <Image
                  alt={name}
                  src={src}
                  width={200}
                  height={200}
                  className="object-cover absolute top-[-20px]"
               /> */}
            </div>
         </div>
         <p className="font-bold text-2xl mt-5">{name}</p>
         <p className="text-base mt-5 mb-5 text-neutral-600 w-2/3 text-center">
            {description}
         </p>
         <button className="button w-full mt-auto">להזמנה</button>
      </div>
   );
}
