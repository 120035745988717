import { Typewriter } from "react-simple-typewriter";
import Character from "../components/character";
import Review from "../components/review";
import { MdPhone } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa";
import { TbTargetArrow } from "react-icons/tb";

const CHARACTERS = [
   {
      src: "/assets/imgs/characters/elprimo.png",
      name: "אל פרימו",
      description: "מתאבק מיוחד ממשחק הברול סטארס ונערץ",
   },
   {
      src: "/assets/imgs/characters/lion.png",
      name: "ליאון",
      description: "הדמות המרכזית במשחק שתלהיב את הילדים ונערצת בפני כל הילדים",
   },
   {
      src: "/assets/imgs/characters/spike.png",
      name: "ספייק",
      description: "אחת הדמויות הנערצות במשחק והמגניבות ביותר שתדליק את הילדים",
   },
];

const REVIEWS = [
   {
      name: "יואב הריס",
      stars: 5,
      review:
         "הבובות הכי טובות בארץ. שירות טוב ומהיר. הבובות האלה עשו לנו ולילדינו את היום",
   },
   {
      name: "נטע פרץ",
      stars: 5,
      review:
         "לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית גולר מונפרר סוברט לורם שבצק יהול, לכנוץ בעריר גק ליץ, ושבעגט ליבם סולגק. בראיט ולחת צורק מונחף, בגורמי מגמש.",
   },
   {
      name: "נועם ירושלמי",
      stars: 5,
      review:
         "לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית גולר מונפרר סוברט לורם שבצק יהול, לכנוץ בעריר גק ליץ, ושבעגט ליבם סולגק. בראיט ולחת צורק מונחף, בגורמי מגמש.",
   },
];

export default function Home() {
   return (
      <main className="">
         {/* <div className="lg:hidden fixed bottom-5 left-5 overflow-hidden p-4 bg-cyan-500 rounded-full shadow-lg">
            <a href="tel:+972545733333">
               <MdPhone size={28} color="#FFF" />
            </a>
         </div>
         <div className="lg:hidden fixed bottom-5 right-5 overflow-hidden p-4 bg-pink-400 rounded-full shadow-lg">
            <a
               href="https://wa.me/972545733333?text=היי,%20אני%20מעוניין%20להזמין%20בובה"
               target="_blank"
            >
               <FaWhatsapp size={28} color="#FFF" />
            </a>
         </div> */}

         {/* First Section */}
         <div className="min-h-[100vh] lg:flex lg:flex-row-reverse pt-[150px]">
            <div className="flex flex-col justify-center items-center lg:items-end mt-10 lg:w-1/2 lg:justify-normal lg:pr-[20%]">
               <h1
                  className={`text-4xl text-center lg:text-end w-full lg:text-6xl font-bold`}
               >
                  בובות ענק{" "}
                  <span className={`block h-[1.5em] lg:h-[1.8em] sm:h-[2em]`}>
                     <Typewriter
                        words={[
                           "בהפתעה ליום הולדת",
                           "ברוול סטארס מיוחדות",
                           "להשכרה לאירועים",
                        ]}
                        loop={false}
                        deleteSpeed={40}
                     />
                  </span>
               </h1>
               <p className="text-base text-neutral-700 text-center mt-3 lg:w-[80%] lg:text-end w-[70%] lg:text-lg">
                  הפתעה מגניבה לקטנטנים שתדליק את המסיבה עם המון ריקודים וכיף!
                  מגוון בובות ענק של דמויות אהובות לבחירה שבאות להרים את היום
                  הולדת להפתיע את הילדים ולעשות שמח לכולם
               </p>

               <div className="flex flex-col lg:justify-end lg:items-end lg:mt-10 lg:gap-5 home-buttons lg:w-full">
                  <a
                     href="tel:+972545733333"
                     className="button mt-5 text-lg lg:mt-0 lg:w-[50%] w-full"
                  >
                     צור קשר
                  </a>
                  <a
                     href="/images"
                     className="button-outline mt-3 text-lg lg:mt-0 lg:w-[50%] w-full"
                  >
                     גלרייה
                  </a>
               </div>

               <div className="lg:hidden flex mt-5">
                  <img
                     alt="Landing Page Image"
                     width={200}
                     height={10}
                     src={"/assets/imgs/landingpage-mobile.png"}
                  />
               </div>
            </div>

            <div className="lg:block hidden">
               <img
                  alt="Landing Page Image"
                  width={700}
                  height={10}
                  src={"/assets/imgs/landingpage.png"}
               />
            </div>
         </div>

         {/*  */}
         <div className="flex flex-col mt-10 mb-20 app-container">
            <h1 className="text-2xl font-semibold lg:text-4xl text-center lg:text-right mb-5">
               בובות ענק לאירועים
            </h1>
            <div className="flex flex-row-reverse items-center justify-start mr-3">
               <TbTargetArrow className="ml-3" color="#f9a8d4" size={38} />
               <p>בובות ענק ליום הולדת</p>
            </div>
            <div className="flex flex-row-reverse items-center justify-start mr-3">
               <TbTargetArrow className="ml-3" color="#f9a8d4" size={38} />
               <p>בובה בהפתעה שהילדים לא ישכחו</p>
            </div>
            <div className="flex flex-row-reverse items-center justify-start mr-3">
               <TbTargetArrow className="ml-3" color="#f9a8d4" size={38} />
               <p>השכרת בובת ענק לאירועים</p>
            </div>
            <div className="flex flex-row-reverse items-center justify-start mr-3">
               <TbTargetArrow className="ml-3" color="#f9a8d4" size={38} />
               <p>חוויה שתעשה לכם את היום הולדת</p>
            </div>
         </div>

         {/* Second Section */}
         <div className="flex flex-col mt-10 mb-20">
            <div className="app-container flex justify-center lg:justify-end">
               <h1 className="bubot-title text-center text-xl md:text-3xl font-semibold">
                  בובות הברוול סטארס המיוחדות שלנו
               </h1>
            </div>
            <div className="app-container">
               <div className="flex flex-col lg:flex-row gap-5 items-center mt-10 md:items-stretch">
                  {CHARACTERS.map((item, index) => (
                     <Character
                        key={index}
                        name={item.name}
                        description={item.description}
                        src={item.src}
                     />
                  ))}
               </div>
            </div>
         </div>

         {/*  */}
         <div className="flex flex-col app-container mb-20 justify-center items-center">
            <h2 className="text-2xl lg:text-4xl font-semibold text-center">
               ?למה דווקא לבחור בנו לאירוע שלכם
            </h2>
            <p className="text-base text-neutral-700 text-center mt-3 lg:text-lg w-full lg:w-[70%] lg:text-end">
               אולי בחרתם בבובות ענק לאירועים, או אולי אטרקציה אחרת, הנקודה
               החשובה היא שבכל אירוע ילדים אתם זקוקים לאטרקציות מעניינות
               ומרתקות. אתם רוצים לצבוע את האירוע שלכם בצבעים של שמחה. להעניק
               לאירוע עומק ולהפוך אותו למהנה ומיוחד – אתם זקוקים לאטרקציות
               שיעזרו לכם לעשות זאת. האם השכרת בובות ענק לאירועים יכולה לעזור
               לכם להפוך את האירוע לכיפי יותר?
            </p>

            <p className="text-base text-neutral-700 text-center mt-3 lg:text-lg w-full lg:w-[70%] lg:text-end">
               לא משנה מה סוג האירוע שאתם עורכים, זה יכול להיות יום הולדת, מסיבת
               סיום או כל אירוע ילדים אחר, השכרת בובות ענק יכולה לשדרג את
               האירוע. דמיינו את פני הילדים במסיבת יום ההולדת כשהם רואים בובות
               ענק מקבלות את פניהם. זה לא משנה אם מדובר בבובה אחת או כמה בובות,
               הן תמיד מושכות את כל תשומת הלב. כל תזוזה של הבובה, כל נפנוף יד
               לשלום וכל אינטראקציה עם הבובה תמיד מושכים תשומת לב בצורה מרתקת.
               לפעמים זה נראה כאילו לבובה יש חיים משלה. בסופו של דבר, זה מושך
               הרבה תשומת לב ומשעשע מאוד. בובות ענק לאירועים מכניסות את הילדים
               לאווירה נעימה והמון כיף.
            </p>
            <h2 className="text-2xl lg:text-4xl font-semibold text-center mt-5">
               בובות ענק לאירועים מפיחות חיים באירוע
            </h2>
            <p className="text-base text-neutral-700 text-center mt-3 lg:text-lg w-full lg:w-[70%] lg:text-end">
               לא משנה מה אתם עושים עם הבובות, כאשר יש בובות ענק לאירועים הן
               תמיד מביאות רוח חיים לאירוע והמון שמחה ואושר. זה אולי נשמע לכם
               ילדותי, אבל חכו שתראו את הפרצופים של הילדים כאשר בובת ענק תעבור
               בין השולחנות ותצטלם איתם. דמיינו את הצחוקים והפוזות למצלמה, הבובה
               תהפוך לכוכבת. ואם קצת יבש על רחבת הריקודים, בובות ענק לאירועים
               יגרמו גם לביישנים להתחיל לרקוד ולקפוץ. הן יכולות להשתלב בקבלת
               הפנים, על רחבת הריקודים ואפילו לצד אטרקציות אחרות.
            </p>
            <h2 className="text-2xl lg:text-4xl font-semibold text-center mt-5">
               תמונות וזיכרונות עם בובות
            </h2>
            <p className="text-base text-neutral-700 text-center mt-3 lg:text-lg w-full lg:w-[70%] lg:text-end">
               כאשר מזמינים בובות ענק לאירועים, כל הילדים רוצים תמונות עם
               הבובות. לא משנה אם זה סלפי מהטלפון או תמונה של צלם מקצועי – זה
               יהיה זיכרון נהדר מהאירוע. בתמונות תוכלו לראות היטב את רגעי האושר
               והשמחה ולהבין שזה היה בדיוק האירוע עליו חלמתם. ואם מדובר באירוע
               משפחתי, זה יכול להפוך לזיכרון משפחתי מיוחד ויקר. אל תתפלאו אם כבר
               במהלך האירוע הילדים יתחילו לשתף תמונות עם הבובות ברשתות החברתיות.
               אתם רק צריכים לדעת איך למנף זאת.
            </p>
         </div>

         {/* Third Section */}
         <div className="flex flex-col app-container justify-center items-center mb-20">
            <h1 className="font-semibold text-2xl text-center">
               מה לקוחותינו <span className="text-cyan-500">אומרים עלינו</span>
            </h1>
            <p className="text-neutral-700 w-[90%] text-center mt-5 text-base">
               יחס ושירות מצוין ללקוחותינו הוא ערך עליון עבורנו. הנה כמה תגובות
               מלקוחותינו המרוצים. אנו מתגאים במתן שירות אישי ומקצועי לכל לקוח,
               ודואגים תמיד להתעדכן בצרכים ובהעדפות שלכם כדי לספק את החוויה
               הטובה ביותר.
            </p>

            <div className="flex flex-col md:flex-row gap-5 mt-5 md:mt-10">
               {REVIEWS.map((item, index) => (
                  <Review key={index} name={item.name} review={item.review} />
               ))}
            </div>
         </div>
      </main>
   );
}
