import { MdMenu, MdClose, MdOutlinePhone } from "react-icons/md";

export default function Navbar() {
   return (
      <nav className="pt-5 pb-5 lg:pl-[20%] lg:pr-[20%] pl-5 pr-5 flex sm:flex-row-reverse flex-row items-center bg-white z-20">
         {/* <h1 className="text-4xl font-bold">בובה במסיבה</h1> */}
         <a href="/" aria-label="Buba Bamesiba Logo">
            <img
               alt="Logo"
               width={150}
               height={50}
               src={"/assets/imgs/logo.png"}
            />
            {/* <Image
               width={150}
               height={50}
               alt="Logo"
               src={require("../../../public/assets/imgs/logo.png")}
            /> */}
         </a>
         <input type="checkbox" id="sidebar-active" />
         <label
            htmlFor="sidebar-active"
            className="open-sidebar-button hover:cursor-pointer ml-auto"
         >
            <MdMenu size={28} color="#000" />
         </label>

         <label htmlFor="sidebar-active" id="nav-overlay"></label>
         <div className="nav-links-container gap-5 ml-auto mr-auto">
            <label
               htmlFor="sidebar-active"
               className="close-sidebar-button hover:cursor-pointer"
            >
               <MdClose size={28} color="#000" />
            </label>

            <a href="/" aria-label="דף הבית" className="nav-link">
               דף הבית
            </a>
            <a href="images" aria-label="גלרייה" className="nav-link">
               גלרייה
            </a>
         </div>

         <div className="sm:flex hidden flex-row items-center">
            <MdOutlinePhone size={24} color="#000" className="mr-2" />
            <div className="flex flex-col">
               <a
                  href="tel:+972545733333"
                  className="text-base hover:underline"
               >
                  +972 54-573-3333
               </a>
               <a
                  href="tel:+972539193300"
                  className="text-base hover:underline"
               >
                  +972 53-919-3300
               </a>
            </div>
         </div>
      </nav>
   );
}
