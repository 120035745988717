import { MdOutlineWhatsapp } from "react-icons/md";
import { FaTiktok, FaInstagram } from "react-icons/fa";

export default function Footer() {
   return (
      <footer className="footer bg-neutral-700">
         <div className="app-container flex flex-row items-center min-h-64">
            <div className="h-full lg:flex hidden flex-col">
               <div className="flex flex-row gap-4 mb-1 lg:mt-0 mt-10">
                  <a
                     href="https://www.tiktok.com/@bubabamesiba"
                     target="_blank"
                     aria-label="Open Tiktok"
                  >
                     <FaTiktok size={28} className="text-neutral-300" />
                  </a>
                  <a
                     aria-label="Open Instagram"
                     href="https://www.instagram.com/bubabamesiba/"
                     target="_blank"
                  >
                     <FaInstagram size={28} className="text-neutral-300" />
                  </a>
                  <a
                     aria-label="Chat on WhatsApp"
                     href="https://wa.me/972545733333?text=היי,%20אני%20מעוניין%20להזמין%20בובה"
                     target="_blank"
                  >
                     <MdOutlineWhatsapp
                        size={28}
                        className="text-neutral-300"
                     />
                  </a>
               </div>
               <span className="text-neutral-300 text-base">
                  עזרה: mail@mail.com
               </span>
            </div>
            <div className="flex lg:flex-row-reverse flex-col flex-1 justify-start items-center h-full">
               <h1 className="footer-title lg:mt-0 mt-10 lg:mb-0 mb-16 lg:w-auto w-full text-center font-bold text-4xl text-white">
                  בובה במסיבה
               </h1>
               <div className="flex flex-col items-end">
                  <div className="flex lg:flex-row flex-col lg:gap-5 gap-2 text-end">
                     <a
                        href="/"
                        className="text-neutral-300 text-lg footer-link-item"
                     >
                        דף הבית
                     </a>
                     <a
                        href="/images"
                        className="text-neutral-300 text-lg footer-link-item"
                     >
                        גלרייה{" "}
                     </a>
                  </div>

                  <div className="lg:hidden w-full flex flex-row gap-4 lg:mb-1 lg:mt-0 mt-5 justify-center items-center">
                     <a
                        href="https://www.tiktok.com/@bubabamesiba"
                        target="_blank"
                        aria-label="Open Tiktok"
                     >
                        <FaTiktok size={28} className="text-neutral-300" />
                     </a>
                     <a
                        aria-label="Open Instagram"
                        href="https://www.instagram.com/bubabamesiba/"
                        target="_blank"
                     >
                        <FaInstagram size={28} className="text-neutral-300" />
                     </a>
                     <a
                        aria-label="Chat on WhatsApp"
                        href="https://wa.me/972545733333?text=היי,%20אני%20מעוניין%20להזמין%20בובה"
                        target="_blank"
                     >
                        <MdOutlineWhatsapp
                           size={28}
                           className="text-neutral-300"
                        />
                     </a>
                  </div>

                  <p className="lg:text-lg text-base text-neutral-300 lg:mt-2 mt-5 lg:mb-0 mb-10">
                     © בובה במסיבה 2024. כל הזוכויות שמורות
                  </p>
               </div>
            </div>
         </div>

         <div className="pt-5 pb-5 bg-neutral-800 flex justify-center items-center">
            <p className="lg:text-xl text-base text-white">
               Developed by{" "}
               <span className="font-bold text-neutral-300">Alex</span>
            </p>
         </div>
      </footer>
   );
}
