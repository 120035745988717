import { MdStar } from "react-icons/md";

export default function Review({ name, review }) {
   return (
      <div className="review-item flex-1">
         <h1 className="text-right font-bold text-2xl mb-3">{name}</h1>
         <div className="flex gap-2 mb-3">
            <MdStar className="text-yellow-400 text-xl" />
            <MdStar className="text-yellow-400 text-xl" />
            <MdStar className="text-yellow-400 text-xl" />
            <MdStar className="text-yellow-400 text-xl" />
            <MdStar className="text-yellow-400 text-xl" />
         </div>
         <p className="text-right text-neutral-500">{review}</p>
      </div>
   );
}
